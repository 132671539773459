import { useState, useEffect } from "react";

import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  InputGroup,
  InputGroupAddon,
  Alert,
} from "reactstrap";

import { Div } from "./DivStyled.js";

import {
  getAllGroups,
  getGroupNumbers
} from "../../services/Client-Schedule/index.js";

import {
  postNewContacts
} from "../../services/Instance/index.js";

import Swal from "sweetalert2";

const ModalAddMoreContacts = ({ isModalOpen, closeModal, campaignId, getInfo }) => {
  const [showGroupNumbers, setShowGroupNumbers] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectionMode, setSelectionMode] = useState('');
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [btnBlocked, setBtnBlocked] = useState(true);
  const [numbersPhone, setNumbersPhone] = useState("");
  const [message, setMessage] = useState({
    numbers: [],
    instance: null,
    groups: null
  });


  const handleGroupSelect = async (groupId) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getGroupNumbers(groupId);
      Swal.close();
      Array.isArray(response.data.resultSet);
      setNumberList([...response.data.resultSet]);
      setShowGroupNumbers(true);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar números do grupo", error);
    }
  };

  const handleAddNewContacts = async (campaignId) => {
    if (
      !(
        (selectionMode === 'digitarNumeros' && message.numbers.length > 0) ||
        (selectionMode === 'escolherGrupos' && selectedGroup !== null && selectedGroup.id !== 0)
      ) ||
      !campaignId
    ) {
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'Por favor preencha os campos obrigatórios!',
      });
      return;
    }

    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let data;

      if (selectionMode === 'digitarNumeros') {
        data = {
          campaignId: campaignId,
          numbers: message.numbers,
        };
      } else if (selectionMode === 'escolherGrupos') {
        data = {
          campaignId: campaignId,
          groupId: selectedGroup.id,
        };
      }

      await postNewContacts(campaignId, data);

      Swal.close();

      setShowAlert(false);

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Contatos adicionados com sucesso',
      });

      setMessage({
        numbers: [],
        groups: null,
        instance: [],
      });

      closeModal();
      setNumbersPhone('');
      setSelectedGroup('');
      setSelectionMode('');
      setNumberList([]);

    } catch (error) {
      console.error('Erro ao adicionar contatos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Não foi possível adicionar contatos',
      });
    }
  };

  const getGroup = async () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getAllGroups().then((res) => {
        Swal.close();
        return res;
      });
      Array.isArray(response.data.resultSet);
      setGroupList([...response.data.resultSet]);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar a lista de grupos", error);
    }
  };

  const handleAddGroup = async () => {
    if (selectedGroup && selectedGroup.id !== 0) {
      setMessage({
        ...message,
        groups: selectedGroup.id,
      });

      setBtnBlocked(true);

      if (selectionMode === 'escolherGrupos' && selectedGroupId !== 0 && selectedGroup.id !== 0) {
        setBtnBlocked(false);
      }
    }
  };

  const handleModeSwitchResponse = async (switchMode) => {
    if (switchMode) {
      if (selectionMode === 'escolherGrupos') {
        setSelectionMode('digitarNumeros');
        setSelectedGroup("");
        setNumberList([]);
        setBtnBlocked(true);
      } else if (selectionMode === 'digitarNumeros') {
        setNumbersPhone("");
        setMessage({
          ...message,
          numbers: [],
          instance: []
        });
        setSelectionMode('escolherGrupos');
        setBtnBlocked(true);
      }
    }
    Swal.close();
  };

  const handleAddPhone = async () => {
    const numbers = message.numbers;
    numbersPhone.split("\n").map((line) =>
      line.split("\t").map((value) => {
        numbers.push(value);
      })
    );
    setMessage({
      ...message,
      numbers: numbers,
    });

    setNumbersPhone("");
    setBtnBlocked(true);
    if (

      (selectionMode === 'digitarNumeros' && message.numbers.length > 0) &&
      message.message &&
      message.instance
    ) {
      setBtnBlocked(false);
    }

  };

  const handleShowModeSwitchModal = () => {
    Swal.fire({
      icon: 'question',
      title: "AVISO",
      text: selectionMode === 'escolherGrupos'
        ? 'Você já selecionou grupos. Se mudar para "Digitar Números", os grupos serão removidos. Deseja continuar?'
        : 'Você já escolheu números de telefone. Se mudar para "Escolher Grupos", os números serão removidos. Deseja continuar?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        handleModeSwitchResponse(true);
      } else {
        handleModeSwitchResponse(false);
      }
    });
  };

  const handleSelectionModeChange = (event) => {
    const selectedMode = event.target.value;

    if (selectionMode === 'escolherGrupos' && selectedGroup && selectedGroup.id !== 0 && selectedGroupId === undefined) {
      handleShowModeSwitchModal();
    } else if (selectionMode === 'digitarNumeros' && message.numbers.length > 0) {
      handleShowModeSwitchModal();
    } else {
      setSelectionMode(selectedMode);
    }
  };

  const handleChange = async (ev) => {
    if (ev.target.name === "number") {
      setNumbersPhone(ev.target.value);
    }
  };

  const handleRemovePhone = (index) => {
    const updatedNumbers = [...message.numbers];
    updatedNumbers.splice(index, 1);
    setMessage((prevMessage) => ({
      ...prevMessage,
      numbers: updatedNumbers,
    }));
  };

  const handleRemoveAllPhones = () => {
    setMessage({ ...message, numbers: [] });
  };

  useEffect(() => {
    getGroup();
  }, []);

  useEffect(() => {
    setShowAlert(showGroupNumbers && numberList.length === 0 && selectedGroup !== null && selectedGroup.id !== 0);
  }, [showGroupNumbers, numberList, selectedGroup]);

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal} className="modal-lg modal-dialog-centered" >
      <ModalHeader toggle={closeModal}>Adicionar números a campanha</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="container">
            <Label className="mb-3 fw-bold">Números ou Grupo?</Label>
            <InputGroup className="row">
              <div className="d-flex flex-column col-12">
                <div>
                  <input
                    type="radio"
                    name="selectionMode"
                    id="digitarNumeros"
                    value="digitarNumeros"
                    checked={selectionMode === 'digitarNumeros'}
                    onChange={handleSelectionModeChange}
                  />
                  <Label className="form-check-label ml-2" for="digitarNumeros">
                    Digitar Números
                  </Label>
                  {selectionMode === 'digitarNumeros' && (
                    <FormGroup className="mt-2">
                      <InputGroup className="col-12">
                        <textarea
                          name="number"
                          value={numbersPhone}
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleChange}
                        ></textarea>
                        <InputGroupAddon addonType="append">
                          <Button color="secondary" onClick={handleAddPhone}>
                            ADICIONAR NÚMEROS
                          </Button>
                        </InputGroupAddon>

                      </InputGroup>
                      <div>
                        {message.numbers.length > 0 ? (
                          <>
                            <div className="mt-2 alert alert-secondary px-4 col-12">
                              <h4 className="text-dark mb-3">Números adicionados</h4>
                              <Div>
                                {message.numbers.map((number, index) => (
                                  <div className="d-flex align-itens-center mb-1">
                                    <span key={index} className="badge badge-success" style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }}>
                                      {number}

                                    </span>
                                    <button
                                      className="btn-danger border-0"
                                      style={{ padding: "2px 5px", fontSize: "12px", textAlign: "center", display: "flex", justifyContent: "center" }}
                                      onClick={() => handleRemovePhone(index)}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </Div>
                            </div>
                            <button
                              className="btn btn-danger"
                              onClick={handleRemoveAllPhones}
                            >
                              Remover Todos
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </FormGroup>
                  )}
                </div>
                <div>
                  <input
                    type="radio"
                    name="selectionMode"
                    id="escolherGrupos"
                    value="escolherGrupos"
                    checked={selectionMode === 'escolherGrupos'}
                    onChange={handleSelectionModeChange}
                  />
                  <Label className="form-check-label ml-2 mt-3" for="escolherGrupos">
                    Escolher Grupo
                  </Label>
                  {selectionMode === 'escolherGrupos' && (
                    <FormGroup className="mt-2">
                      <InputGroup style={{ width: "15rem" }}>
                        <select
                          className="form-control"
                          name="clientGroup"
                          value={selectedGroup ? selectedGroup.id : ''}
                          onChange={(e) => {
                            const selectedGroupId = e.target.value;
                            const selectedGroup = groupList.find((group) => group.id === selectedGroupId);
                            setSelectedGroup(selectedGroup ? { id: parseInt(selectedGroupId), name: selectedGroup.name } : null);
                            handleGroupSelect(selectedGroupId)

                            handleAddGroup();
                          }}
                        >
                          <option value="">Selecione...</option>
                          {groupList.map((group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                    </FormGroup>
                  )}

                  {showGroupNumbers ? (
                    numberList.length > 0 ? (
                      <div className="mt-2 alert alert-secondary">
                        <div className="d-flex flex-lg-row flex-column mb-4">
                          <h4 className="text-dark m-0 mr-2 mb-lg-0 mb-2">Números do Grupo</h4>
                          <div className="badge badge-secondary d-flex align-items-center" style={{ maxWidth: "120px" }}>Quantidade: {numberList.length}</div>
                        </div>
                        <div className="d-flex flex-wrap" style={{ maxHeight: "300px", overflow: "auto", gap: "10px", maxWidth: "70vw" }}>
                          {numberList.map((number, index) => (
                            <span key={index} className="badge badge-success mb-1">
                              {number}
                            </span>
                          ))}
                        </div>
                      </div>
                    ) : (
                      showAlert && selectedGroup && (
                        <Alert className="alert alert-warning mb-3 text-warning">
                          Nenhum telefone cadastrado neste grupo.
                        </Alert>
                      )
                    )
                  ) : null}

                </div>
              </div>

            </InputGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={closeModal}
        >
          Fechar
        </Button>
        <Button
          color="success"
          onClick={() => {
            handleAddNewContacts(
              parseInt(campaignId)
            );
          }}
        >
          Salvar
        </Button>
      </ModalFooter>
    </ Modal>
  );
}

export default ModalAddMoreContacts;