import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { FaPlus, FaMinus } from "react-icons/fa";

import { getAllGroups, updateClient } from "../../../services/Client-Schedule";

import RegisterGroup from "./RegisterGroup";

import {
  ModalBodyGroupStyled,
  ListTell,
  ContainerEditClient,
  ListGroup,
} from "../style/ClientScheduleStyled.js";

import Swal from "sweetalert2";
import { phoneMask } from "../../../utils/mask.js";

const ClientEdit = ({ isOpen, toggleModal, client, onUpdateClients }) => {
  const [name, setName] = useState("");
  const [documentValue, setDocumentValue] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [telefones, setTelefones] = useState([]);
  const [numeroTelefone, setNumeroTelefone] = useState("");
  const [tipoTelefone, setTipoTelefone] = useState("");
  const [groups, setGroups] = useState([]);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [observationText, setObservationText] = useState("");
  const [observationsList, setObservationsList] = useState([]);
  
  let date = birthDate;
  let dateFormatEua = date.split('/').reverse().join('-');

  const toggleModalGroup = () => {
    setShowModalGroup(!showModalGroup);
  };

  const addObservation = () => {
    if (observationText) {
      setObservationsList([...observationsList, { text: observationText }]);
      setObservationText("");
    }
  };

  const removeObservation = (index) => {
    const observacoesAtualizadas = [...observationsList];
    observacoesAtualizadas.splice(index, 1);
    setObservationsList(observacoesAtualizadas);
  };

  const handleSave = async (clientId) => {
    if (!name || telefones.length <= 0) {
      Swal.fire({
        icon: "error",
        title: "Campos em branco",
        text: "O Nome e telefone são obrigatórios",
      });
      return;
    }

    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = {
        id: client.id,
        name,
        document: documentValue,
        birthDate: dateFormatEua,
        phoneNumbers: telefones,
        groups: groups.map((group) => ({
          id: parseInt(group.id),
          name: group.name,
        })),
        observations: observationsList,
      };

      const response = await updateClient(clientId, data);

      if (response) {
        await Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Cliente atualizado com sucesso!",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

      toggleModal();
      onUpdateClients();
    } catch (error) {
      console.log(error);
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;

      const showError = async () => {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      };

      showError();
    }
  };

  const getGroup = async () => {
    try {
      const response = await getAllGroups();
      setGroupList([...response.data.resultSet]);
    } catch (error) {
      console.log(error);
      console.error("Erro ao buscar a lista de grupos");
    }
  };

  const addTelefone = () => {
    if (numeroTelefone && tipoTelefone) {
      const newTelefone = { number: numeroTelefone, type: tipoTelefone };
      if (
        !telefones.some(
          (telefone) =>
            telefone.number === newTelefone.number &&
            telefone.type === newTelefone.type
        )
      ) {
        setTelefones((prevTelefones) => [...prevTelefones, newTelefone]);
        setNumeroTelefone("");
        setTipoTelefone("");
      } else {
        Swal.fire({
          icon: "warning",
          title: "Telefone Duplicado",
          text: "Você já adicionou este telefone. Por favor, escolha outro.",
        });
      }
    }
  };

  const removeTelefone = (index) => {
    const updatedTelefones = [...telefones];
    updatedTelefones.splice(index, 1);
    setTelefones(updatedTelefones);
  };

  const addGroup = () => {
    if (selectedGroup.id && selectedGroup.name.trim() !== "") {
      if (!groups.some((group) => group.id === selectedGroup.id)) {
        setGroups((prevGroups) => [...prevGroups, selectedGroup]);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Grupo Duplicado",
          text: "Você já selecionou este grupo. Por favor, escolha outro.",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
      setSelectedGroup({ id: "", name: "" });
    }
  };

  const removeGroup = (index) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(index, 1);
    setGroups(updatedGroups);
  };

  useEffect(() => {
    getGroup();
    if (client) {
      setName(client.name || "");
      setDocumentValue(client.document || "");
      setBirthDate(client.birthDate || "");
      setTelefones(client.phoneNumbers || []);
      setGroups(client.groups || []);
      setObservationsList(client.observations || []);
    }
  }, [client]);

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="modal-lg">
      <ModalHeader toggle={toggleModal}>Editar Cliente</ModalHeader>
      <ModalBodyGroupStyled>
        <ContainerEditClient>
          <FormGroup>
            <label>Nome do Cliente</label>
            <Input
              type="text"
              id="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label>Documento</label>
            <Input
              type="text"
              placeholder="Digite o documento"
              value={documentValue}
              onChange={(e) => setDocumentValue(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label>Data de Nascimento</label>
            <Input
              type="date"
              placeholder="DD/MM/AAAA"
              className="form-control"
              value={dateFormatEua}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label>Telefones</label>
            <InputGroup>
              <Input
                type="text"
                placeholder="Número"
                value={numeroTelefone}
                onChange={(e) => setNumeroTelefone(phoneMask(e.target.value))}
              />
              <select
                className="form-control"
                placeholder="Tipo"
                value={tipoTelefone}
                onChange={(e) => setTipoTelefone(e.target.value)}
              >
                <option value="">Selecione...</option>
                <option value="whatsapp">WhatsApp</option>
                <option value="sms">SMS</option>
                <option value="fixed">Fixo</option>
              </select>
              <InputGroupAddon addonType="append">
                <Button color="info" onClick={addTelefone}>
                  <FaPlus />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {telefones.length > 0 && (
              <ListTell>
                <label>Telefones Adicionados:</label>
                {telefones.map((telefone, index) => (
                  <div key={index} className="mb-2">
                    <InputGroup>
                      <Input type="text" value={telefone.number} disabled />
                      <Input type="text" value={telefone.type} disabled />
                      <InputGroupAddon addonType="append">
                        <Button
                          color="danger"
                          onClick={() => removeTelefone(index)}
                        >
                          <FaMinus />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                ))}
              </ListTell>
            )}
          </FormGroup>
          <FormGroup>
            <label>Grupos:</label>
            <InputGroup>
              <select
                className="form-control"
                name="clientGroup"
                required
                value={selectedGroup.id}
                onChange={(e) => {
                  const selectedGroupId = e.target.value;
                  const selectedGroup = groupList.find(
                    (group) => group.id === selectedGroupId
                  );
                  setSelectedGroup({
                    id: selectedGroupId,
                    name: selectedGroup.name,
                  });
                }}
              >
                <option value="">Selecione...</option>
                {groupList.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
              <InputGroupAddon addonType="append">
                <Button color="info" onClick={() => addGroup(selectedGroup)}>
                  <FaPlus />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {groups.length > 0 && (
              <ListGroup>
                {groups.map(
                  (group, index) =>
                    group.name && (
                      <div key={index} className="mb-2">
                        <InputGroup>
                          <Input type="text" value={group.name} disabled />
                          <InputGroupAddon addonType="append">
                            <Button
                              color="danger"
                              onClick={() => removeGroup(index)}
                            >
                              <FaMinus />
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                    )
                )}
              </ListGroup>
            )}
            <Button className="mt-3" onClick={toggleModalGroup}>
              Adicionar Grupo
            </Button>
          </FormGroup>
          <FormGroup>
            <label>Observações:</label>
            <InputGroup>
              <Input
                type="textarea"
                placeholder="Digite uma observação"
                value={observationText}
                onChange={(e) => setObservationText(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <Button color="info" onClick={addObservation}>
                  <FaPlus />
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {observationsList.length > 0 && (
              <ListGroup>
                {observationsList.map((observation, index) => (
                  <div key={index} className="mb-2">
                    <InputGroup>
                      <Input type="text" value={observation.text} disabled />
                      <InputGroupAddon addonType="append">
                        <Button
                          color="danger"
                          onClick={() => removeObservation(index)}
                        >
                          <FaMinus />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                ))}
              </ListGroup>
            )}
          </FormGroup>
        </ContainerEditClient>
      </ModalBodyGroupStyled>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Fechar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            handleSave(client.id);
          }}
        >
          Salvar
        </Button>
      </ModalFooter>
      <RegisterGroup isOpen={showModalGroup} toggleModal={toggleModalGroup} />
    </Modal>
  );
};

export default ClientEdit;
