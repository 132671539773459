import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import {
  generateInvoiceService,
  showDetailService,
} from "../../services/License";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Finance = () => {
  const [license, setLicense] = useState({
    dateExpire: null,
    valuePayment: null,
  });
  const history = useHistory()

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const handleShowDetails = () => {
    Swal.fire({
      title: "Carregando",
      html: "Por favor, aguarde",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showDetailService()
      .then(({ data }) => {
        Swal.close();
        setLicense(data?.resultSet);
      })
      
      .catch((error) => {
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const handleGenerateInvoice = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja emitir uma nova fatura?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: "Não, sair",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: "Emitindo fatura",
        html: "Por favor, aguarde",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      generateInvoiceService()
        .then(({ data }) => {
          if (!data?.invoice) {
            throw Error(
              "Não foi possível emitir a fatura, por favor verifique!"
            );
          }

          Swal.fire({
            icon: "success",
            title: "Fatura emitida",
            text: "Realize o pagamento da fatura abaixo:",
            html: `<a href="${data?.invoice}" class="btn btn-success" target="_blank">VISUALIZAR BOLETO</a>`,
            showConfirmButton: true,
            confirmButtonText: "Ok, sair",
          });
          if (user?.requiredOnboarding > 0) {
            history.push("/admin/onboarding");
          } else {
            history.push("/admin/dashboard")
          }
        })
        .catch((error) => {
          const code = !error.response ? "00000" : error.response.data.code;
          const message = !error.response
            ? "Ocorreu um erro inesperado"
            : error.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
            showConfirmButton: true,
          });
        });
    });
    //
  };

  useEffect(() => {
    handleShowDetails();
  }, []);

  if (!license?.dateExpire) {
    return <></>;
  }

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Card className="border-0 mb-3 mt-3">
            <CardHeader className="border-0 bg-white">
              <h4 className="headline-2">Faturas abertas</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <Card className="rounded border shadow-lg mb-3">
                    <CardBody className="pt-3 pb-3">
                      <p>Valor da mensalidade</p>
                      <h2 className="text-success font-weight-bold mb-0 mt-2">
                        R$ {license.valuePayment}
                      </h2>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="rounded border shadow-lg mb-3">
                    <CardBody className="pt-3 pb-3">
                      <p>Data de Vencimento:</p>
                      <h1
                        className="headline-3 text-muted mb-0 mt-2"
                        style={{ fontSize: "30px" }}
                      >
                        {license.dateExpire}
                      </h1>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="rounded shadow-lg bg-light mb-3">
                    <CardBody className="pt-3 pb-3 text-center">
                      <p>Realizar pagamento</p>
                      <Button
                        className="btn btn-success btn-lg btn-rounded mt-2 w-100"
                        onClick={handleGenerateInvoice}
                      >
                        PAGAR AGORA
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Finance;
