import React, { useState, useEffect } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";

import {
    getChatbot,
    setStatusService,
} from "../../../services/Chatbot/index.js";
import s from "../Chatbot.module.scss";
import Swal from "sweetalert2";
import EditModal from "./EditModal.js";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const ChatbotList = ({
    handleListChatBot = () => { },
    setChatbots = () => { },
    chatbots
}) => {
    const history = useHistory();
    const [editingChatbot, setEditingChatbot] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const setStatus = async (chatbotId, active) => {
        const action = Number(active) === 0 ? "Inativar" : "Ativar";

        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: `Você tem certeza que deseja ${action} o chat?`,
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar",
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            setStatusService(chatbotId, active)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        title: "Operação realizada com sucesso.",
                        confirmButtonText: "Fechar",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: true,
                    });
                    handleListChatBot();
                })
                .catch((err) => {
                    const code = !err.response ? "00000" : err.response.data.code;
                    const message = !err.response
                        ? "Ocorreu um erro inesperado"
                        : err.response.data.message;
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `[${code}] ${message}`,
                        showConfirmButton: true,
                    });
                });
        });
    };

    const updateChatbot = (chatbotId, updatedChatbotData) => {
        const updatedChatbots = chatbots.map((chatbot) => {
            if (chatbot.id === chatbotId) {
                return {
                    ...chatbot,
                    title: updatedChatbotData.title,
                    instanceId: updatedChatbotData.instanceId,
                };
            }
            return chatbot;
        });
        setChatbots(updatedChatbots);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const openEditModal = (chatbot) => {
        setEditingChatbot(chatbot);
        toggleModal();
    };
    
    return (
        <Card className="border-0">
            <CardHeader className="border-0 bg-white">
                <Row>
                    <Col md="9" xl="9">
                        <div className={s.tableTitle}>
                            <div className="headline-2">Listagem</div>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <div className="widget-table-overflow text-center">
                    {chatbots.length > 0 ? (
                        <Table
                            className={`table-striped table-borderless table-hover ${s.statesTable}`}
                            responsive
                        >
                            <thead>
                                <tr>
                                    <th className="w-14">Data Cadastro</th>
                                    <th className="w-14">Instancia</th>
                                    <th className="w-14">Titulo</th>
                                    <th className="w-14">Status</th>
                                    <th className="w-14">Opções</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chatbots.map((chatbot, key) => (
                                    <tr key={chatbot.id}>
                                        <td>
                                            <strong>{chatbot.createdAt}</strong>
                                        </td>
                                        <td>
                                            <strong className="badge badge-info">
                                                {chatbot.instanceName}
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>{chatbot.title}</strong>
                                        </td>
                                        <td>
                                            {Number(chatbot.active) === 1 ? (
                                                <span className="badge badge-success">ATIVO</span>
                                            ) : (
                                                <span className="badge badge-danger">INATIVO</span>
                                            )}
                                        </td>
                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle caret color="secondary">
                                                    Opções
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        style={{ color: "black" }}
                                                        onClick={() => openEditModal(chatbot)}
                                                    >
                                                        Editar
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        style={{ color: "black" }}
                                                        onClick={() =>
                                                            history.push(
                                                                `/admin/chatbot/question/${chatbot.id}`
                                                            )
                                                        }
                                                    >
                                                        Cadastrar Ações
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() =>
                                                            setStatus(
                                                                chatbot.id,
                                                                Number(chatbot.active) === 1 ? 0 : 1
                                                            )
                                                        }
                                                    >
                                                        {Number(chatbot.active) === 0
                                                            ? "Ativar"
                                                            : "Inativar"}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className="alert alert-secondary">
                            <h4 className="text-dark text-center">
                                Não existem chatbots cadastrados no momento.
                            </h4>
                        </div>
                    )}
                </div>
            </CardBody>
            {editingChatbot && (
                <EditModal
                    isOpen={showModal}
                    toggleModal={toggleModal}
                    className={`${s.modal} centered-modal`}
                    chatbotData={editingChatbot}
                    onUpdateChatbot={updateChatbot}
                />
            )}
        </Card>
    );
}




export default ChatbotList;
