import api from "../api";

export const createUser = async (
  user = { name: "", email: "", password: "" , recommendation: '', othersRecommendation: ''}
) => {
  return await api.post("/api/v1/user", user);
};

export const listUsers = async (
  user = { name: "", email: "", password: "" , recommendation: '', othersRecommendation: ''}
) => {
  return await api.post("/api/v1/user", user);
};

export const createAddress = async (register) => {
  return await api.post("/api/v1/address", register);
};

export const updatePasswordService = async (register) => {
  return await api.put("/api/v1/update-password", register);
};

export const resetPasswordService = async (email) => {
  return await api.post("/api/v1/reset-password", { email });
};