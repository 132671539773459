import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardHeader,
  Alert,
  Label,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  Modal,
  Button,
  ModalFooter,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  CardBody,
  Table,
  CardFooter
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import s from "./Chatbot.module.scss";
import {
  getAllMessages,
  listAllInstances,
} from "../../services/Instance/index.js";
import {
  clientStatus,
  getChatbot,
  getWhiteList,
  phoneWhiteList,
  sendChatbot
} from "../../services/Chatbot/index.js";
import Swal from "sweetalert2";
import ChatbotModal from "./components/RegisterModal.js";
import { CardFooterStyled } from "./components/ModalStyle.js";
import ChatbotList from "./components/ChatbotList.js";
import { phoneMask } from "../../utils/mask.js";

const Chatbot = () => {
  const [list, setList] = useState([]);
  const [instances, setInstances] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [newChatbotData, setNewChatbotData] = useState({});
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    dateType: "createdAt",
    numberSend: null,
    status: null,
    limit: 15,
    previusLimit: 0,
    title: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [showWhiteListModal, setShowWhiteListModal] = useState(false);
  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);
  const [enableButtons, setEnableButtons] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showBtnMoreClients, setShowBtnMoreClients] = useState(false);
  const [loadingMoreClients, setLoadingMoreClients] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [clientListFilter, setClientListFilter] = useState({
    limit: 15,
    previusLimit: 0,
  });
  const [dropdownStates, setDropdownStates] = useState([]);

  const handleListInstance = () => {
    listAllInstances().then((res) => {
      setInstances(res.data.resultSet);
    });
  };

  const handleList = async (isMore = false) => {
    try {
      setLoadingList(true);

      Swal.fire({
        title: 'Carregando, Por favor aguarde...',
        text: 'Por favor aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await getWhiteList();
      const newRecords = response.data.resultSet;
      const hasMoreRecords = newRecords.length > 0;

      if (hasMoreRecords) {
        if (isMore) {
          setList((prevList) => [...prevList, ...newRecords]);
        } else {
          setList(newRecords);
        }

        setShowBtnMoreClients(newRecords.length >= clientListFilter.limit);
      } else {
        setList([]);
        setShowBtnMoreClients(false);
      }

      setClientListFilter((prevFilter) => ({
        ...prevFilter,
        previusLimit: newRecords.length,
      }));

      Swal.close();
    } catch (error) {
      console.error('Error fetching data:', error.message);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Falha ao obter os dados da white list.',
        showConfirmButton: true,
      });
    }
  };

  const handleSave = async () => {
    try {
      if (!phoneNumber) {
        Swal.fire({
          icon: 'info',
          title: 'Campos em branco',
          text: 'O telefone é obrigatório!',
          showConfirmButton: true,
        });
        return;
      }

      Swal.fire({
        title: 'Carregando, Por favor aguarde...',
        text: 'Por favor aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const number = {
        phone: phoneNumber,
      };

      const response = await phoneWhiteList(number);

      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Telefone cadastrado com sucesso!',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        document.getElementById("phoneNumberInput").value = "";
        setPhoneNumber("");
        setShowBtnMoreClients(true);
        handleList();
      }
    } catch (error) {
      const code = !error.response ? '00000' : error.response.data.code;
      const message = !error.response
        ? 'Ocorreu um erro inesperado'
        : error.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const toggleStatus = async (whiteListId, active) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja excluir o cliente?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      clientStatus(whiteListId, active)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Operação realizada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
            showConfirmButton: true,
          });
        });
    });
  };

  const getMessages = async (isMore = false) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
    };
    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0,
      });

      filterSearch = {
        ...filter,
        previusLimit: 0,
      };
    }

    await getAllMessages(filterSearch).then(({ data }) => {
      setEnableButtons(filter.status === "0" ?? false);
      if (!data?.status) return;
      Swal.close();

      if (isMore) {
        setList([...list, ...data.resultSet.result]);
        setShowBtnMoreRegisters(data?.resultSet?.result?.length > 0);
      } else {
        setList(data?.resultSet?.result ?? []);
        setShowBtnMoreRegisters(data?.resultSet?.result?.length > 15);
      }
      setFilter({
        ...filter,
        previusLimit: data.resultSet.limit,
      });
    });
  };

  const handleSendChatbot = async () => {
    try {
      const response = await sendChatbot(newChatbotData);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Chatbot cadastrado com sucesso!",
          showConfirmButton: true,
        });
        setShowModal(false);
      }
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleWhiteListModal = () => {
    setShowWhiteListModal(!showWhiteListModal);
    setPhoneNumber("");
    setClientListFilter((prevFilter) => ({
      ...prevFilter,
      limit: 15,
    }));

    if (list.length > 15) {
      setShowBtnMoreClients(true);
    } else {
      setShowBtnMoreClients(false);
    }
  };

  const handleListChatBot = () => {
    getChatbot()
      .then((response) => {
        setChatbots(response.data.resultSet);
        setDropdownStates(
          new Array(response.data.resultSet.length).fill(false)
        );
      })
      .catch((error) => {
        console.error("Erro ao obter a lista de chatbots:", error);
      });
  };

  useEffect(() => {
    getMessages(true);
    handleListInstance();
  }, []);

  useEffect(() => {
    handleListChatBot();
  }, []);

  useEffect(() => {
    setLoadingList(false);
  }, [list]);

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Alert color="info" className="text-info">
            <strong>Obs: </strong> Funcionalidade em fase beta. Sugestões,
            Melhorias ou Bugs por favor informar no atendimento.
          </Alert>
          <Card className="border-0 mb-5 mt-3">
            <CardHeader className="border-0 bg-white">
              <h4 className="headline-2">Realizar Cadastro</h4>
            </CardHeader>
            <CardFooterStyled className="border-0 bg-white">
              <button
                className="btn btn-success btn-md"
                onClick={() => setShowModal(true)}
              >
                Novo Registro
              </button>
              <button
                className="btn btn-primary btn-md"
                onClick={() => {
                  setShowWhiteListModal(true)
                  handleList()
                }}
              >
                Cadastrar na Whitelist
              </button>
            </CardFooterStyled>
            <ChatbotModal
              isOpen={showModal}
              toggleModal={toggleModal}
              className={`${s.modal} centered-modal`}
              onSave={handleSendChatbot}
              handleListChatBot={handleListChatBot}
            />
          </Card>
          <Row className="gutter">
            <Col>
              <Widget>
                <ChatbotList
                  handleListChatBot={handleListChatBot}
                  chatbots={chatbots}
                  setChatbots={setChatbots}
                />
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={showWhiteListModal} toggle={toggleWhiteListModal} className="modal-xl">
        <ModalHeader toggle={toggleWhiteListModal}>Cadastrar na Whitelist</ModalHeader>
        <ModalBody style={{ backgroundColor: "#F7F8FB" }}>
          <Alert
            color="warning"
          >
            <p className="text-warning">Ao cadastrar um número, o mesmo não terá acesso ao serviço de Chatbot.</p>
          </Alert>
          <Card className="border-0 mb-4">
            <CardBody className="pb-2">
              <FormGroup>
                <Label>Número de Whatsapp:</Label>
                <Input
                  id="phoneNumberInput"
                  type="text"
                  placeholder="Digite o número de Whatsapp"
                  value={phoneMask(phoneNumber)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const sanitizedValue = inputValue.replace(/\D/g, '');
                    if (sanitizedValue.length <= 11) {
                      setPhoneNumber(sanitizedValue);
                    }
                  }}
                />
              </FormGroup>
            </CardBody>
            <CardFooter className="bg-white border-0 pt-0 mb-2">
              <Button color="primary" onClick={handleSave}>
                Cadastrar
              </Button>
            </CardFooter>
          </Card>
          <Card className="border-0">
            {loadingList ? (
              <p className="p-4 text-primary">Aguarde, a listagem está carregando...</p>
            ) : (
              <CardBody>
                <Row className="gutter">
                  <Col>
                    <Widget>
                      <Card className="border-0">
                        <CardHeader className="border-0 bg-white">
                          <Row>
                            <Col md="9" xl="9">
                              <div className="">
                                <div className="headline-2">Lista de Clientes</div>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <div className="widget-table-overflow text-center">

                            {list.length > 0 ? (
                              <>
                                <Table
                                  className={`table-striped table-borderless table-hover`}
                                  responsive
                                >
                                  <thead>
                                    <tr>
                                      <th className="w-25">Data de Cadastro</th>
                                      <th className="w-25">Telefone</th>
                                      <th className="w-25">Opções</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {list
                                      .slice(0, clientListFilter.limit)
                                      .map((clientWhiteList, key) => (
                                        <tr key={key}>
                                          <td>
                                            <p>
                                              <strong>{clientWhiteList.createdAt}</strong>
                                            </p>
                                          </td>
                                          <td>
                                            <p>
                                              <strong className="text">
                                                {phoneMask(clientWhiteList.phone)}
                                              </strong>
                                            </p>
                                          </td>
                                          <td>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                caret
                                                style={{ color: "black" }}
                                              >
                                                OPÇÕES
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={() => toggleStatus(clientWhiteList.id, 0)}
                                                >
                                                  Excluir
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                  {showBtnMoreClients && (
                                    <tbody>
                                      <tr>
                                        <td colSpan={6}>
                                          <Button
                                            className="w-100"
                                            disabled={loadingMoreClients}
                                            onClick={() => {
                                              setClientListFilter((prevFilter) => ({
                                                ...prevFilter,
                                                limit: prevFilter.limit + 5,
                                              }));
                                              handleList();
                                            }}
                                          >
                                            {loadingMoreClients ? 'Carregando...' : 'Carregar mais...'}
                                          </Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </Table>
                              </>
                            ) : (
                              <div className="alert alert-secondary">
                                <h4 className="text-dark text-center">
                                  Não possuem registros no momento.
                                </h4>
                              </div>
                            )
                            }
                          </div>
                        </CardBody>
                      </Card>
                    </Widget>
                  </Col>
                </Row>
              </CardBody>
            )}
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleWhiteListModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Chatbot;