import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Input,
    FormGroup
} from 'reactstrap';

import { listAllInstances } from '../../../services/Instance/index.js';
import { sendChatbot } from '../../../services/Chatbot/index.js';

import Swal from 'sweetalert2';
import { ModalBodyStyled } from './ModalStyle.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const ChatbotModal = ({ isOpen, toggleModal, title, handleListChatBot = () => { } }) => {
    const history = useHistory();
    const [titleValue, setTitle] = useState(title || '');
    const [instances, setInstances] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');
    const [instanceId, setInstanceId] = useState('');

    const resetModal = () => {
        setTitle('');
        setInstanceId('');
        setSelectedInstance('');
        toggleModal();
    };

    const handleSave = async () => {
        if (!titleValue || !instanceId) {
            Swal.fire({
                icon: 'error',
                title: 'Campos em branco',
                text: 'Tanto o nome quanto o ID da instância precisam ser preenchidos.',
                showConfirmButton: true,
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const chatbotData = {
                title: titleValue,
                instanceId: parseInt(instanceId)
            };

            const response = await sendChatbot(chatbotData);

            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Chatbot cadastrado com sucesso!',
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                resetModal();
                handleListChatBot();
                history.push(`/admin/chatbot/question/${response.data.resultSet}`);
            }
        } catch (error) {
            const code = !error.response ? '00000' : error.response.data.code;
            const message = !error.response ? 'Ocorreu um erro inesperado' : error.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleChangeFilter = (ev) => {
        if (ev.target.id === "instance") {
            setSelectedInstance(ev.target.value);
            setInstanceId(ev.target.value);
        }
        if (ev.target.id === "title") {
            setTitle(ev.target.value);
        }
    };

    useEffect(() => {
        listAllInstances()
            .then((res) => {
                setInstances(res.data.resultSet);
            })
            .catch((error) => {
                console.error("Error fetching instances:", error);
            });
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={resetModal} className="modal-lg">
            <ModalHeader toggle={resetModal}>Cadastro de Novo Chatbot</ModalHeader>
            <ModalBodyStyled>
                <FormGroup>
                    <label>Instância</label>
                    <select
                        id="instance"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                        value={selectedInstance}
                    >
                        <option value="">Selecione...</option>
                        {instances.length > 0 ? (
                            instances.map((instance, key) => (
                                <option key={key} value={instance.id}>
                                    {instance.name}
                                </option>
                            ))
                        ) : (
                            <></>
                        )}
                    </select>
                </FormGroup>
                <FormGroup>
                    <label>Nome do Chatbot</label>
                    <Input
                        type="text"
                        placeholder="Digite o nome do Chatbot"
                        value={titleValue}
                        id="title"
                        name="title"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <label>ID da Instância</label>
                    <Input
                        type="number"
                        placeholder="Selecione a Instância"
                        value={instanceId}
                        onChange={(e) => setInstanceId(e.target.value)}
                        disabled
                    />
                </FormGroup>
            </ModalBodyStyled>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={resetModal}
                >
                    Fechar
                </Button>
                <Button
                    color="primary"
                    onClick={handleSave}
                >
                    Cadastrar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ChatbotModal;