import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Card,
    CardHeader,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Table,
    DropdownItem,
} from "reactstrap";
import Swal from "sweetalert2";
import {
    registerShortcutMessages,
    getRegisterShortcutMessages,
    setStatusShortcutMessages,
    updateShortcutMessages
}
    from "../../services/Schortcut-messages";

const ShortcutMessage = () => {
    const [showModal, setShowModal] = useState(false);
    const [shortcutList, SetShortcutList] = useState([]);
    const [shortcutListData, setShortcutListData] = useState({
        id: null,
        title: "",
        message: "",
    });

    const toggleModal = () => {
        if (showModal) {
            resetForm();
        }
        setShowModal(!showModal);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShortcutListData({ ...shortcutListData, [name]: value });
    };

    const validateFields = () => {
        const { title, message } = shortcutListData;


        if (!title || !message) {
            Swal.fire({
                icon: "error",
                title: "Erro",
                text: "Todos os campos são obrigatórios!",
            });
            return false;
        }


        return true;
    };

    const handleSave = async () => {
        if (!validateFields()) return;

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            if (shortcutListData.id) {
                await updateShortcutMessages(shortcutListData.id, shortcutListData);
                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Mensagem atualizada com sucesso!",
                });
            } else {
                await registerShortcutMessages(shortcutListData);
                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Mensagem cadastrada com sucesso!",
                });
            }

            handleList();
            toggleModal();
            resetForm();
        } catch (error) {
            const code = !error.response ? '00000' : error.response.data.code;
            const message = !error.response
                ? 'Ocorreu um erro inesperado'
                : error.response.data.message;
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const resetForm = () => {
        setShortcutListData({
            id: null,
            title: "",
            message: "",
        });
    };

    const handleList = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            const response = await getRegisterShortcutMessages();
            const data = response.data.resultSet;

            if (data.length > 0) {
                Swal.close();
                SetShortcutList(data);
            } else {
                Swal.close();
                SetShortcutList([]);
            }
        } catch (err) {
            Swal.close();
            const code = !err.response ? "00000" : err.response.data.code;
            const message = !err.response
                ? "Ocorreu um erro inesperado"
                : err.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const toggleEditModal = (shortcut) => {
        setShortcutListData({
            id: shortcut.id,
            title: shortcut.title,
            message: shortcut.message,
        });
        toggleModal();
    };

    const toggleStatus = async (messageId, active) => {

        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: `Você tem certeza que deseja excluir a mensagem?`,
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar",
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            setStatusShortcutMessages(messageId, active)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        title: "Operação realizada com sucesso.",
                        confirmButtonText: "Fechar",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: true,
                    });
                    handleList();
                })
                .catch((err) => {
                    const code = !err.response ? "00000" : err.response.data.code;
                    const message = !err.response
                        ? "Ocorreu um erro inesperado"
                        : err.response.data.message;
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `[${code}] ${message}`,
                        showConfirmButton: true,
                    });
                });
        });
    };

    useEffect(() => {
        handleList();
    }, []);

    return (
        <div>
            <Row>
                <Col xs={12} lg={12}>
                    <Card className="border-0 mb-5 mt-3 shadow">
                        <CardHeader className="border-0 bg-white">
                            <h4 className="headline-2">Menságem rápida</h4>
                        </CardHeader>
                        <CardFooter className="border-0 bg-white">
                            <button className="btn btn-success btn-md" onClick={toggleModal}>
                                Nova mensagem
                            </button>
                        </CardFooter>
                    </Card>
                </Col>

                <Col>
                    <Card className="border-0">
                        <CardHeader className="border-0 bg-white">
                            <Row>
                                <Col md="9" xl="9">
                                    <h4 className="headline-2">Menságens cadastradas</h4>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="widget-table-overflow text-center">
                                {shortcutList.length > 0 ? (
                                    <Table className="table-striped table-borderless table-hover" responsive>
                                        <thead>
                                            <tr>
                                                <th className="w-14">NOME</th>
                                                <th className="w-14">MENSAGEM</th>
                                                <th className="w-14">OPÇÕES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shortcutList.map((record, key) => (
                                                <tr key={key}>
                                                    <td><strong>{record.title}</strong></td>
                                                    <td>{record.message}</td>
                                                    <td>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle caret style={{ color: 'black' }}>
                                                                OPÇÕES
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => toggleEditModal(record)}>
                                                                    <i className="fa-solid fa-pen-to-square"></i> Editar
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() =>
                                                                        toggleStatus(
                                                                            record.id,
                                                                            Number(record.active) === 0
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa-solid fa-trash mr-1"></i>
                                                                    Excluir
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="alert alert-secondary">
                                        <h4 className="text-dark text-center">
                                            Não possuem registros no momento.
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={showModal} toggle={toggleModal} className="modal-dialog-centered modal-lg">
                <ModalHeader toggle={toggleModal}>
                    {shortcutListData.id ? "Editar Operador" : "Cadastrar Operador"}
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="p-lg-2 p-0">
                        <Label for="title">Titulo <span className="text-danger">*</span></Label>
                        <Input
                            type="text"
                            id="title"
                            name="title"
                            value={shortcutListData.title}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup className="p-lg-2 p-0">
                        <Label for="message">Mensagem<span className="text-danger">*</span></Label>
                        <Input
                            type="textarea"
                            id="message"
                            name="message"
                            value={shortcutListData.message}
                            onChange={handleChange}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={handleSave}>
                        {shortcutListData.id ? "Atualizar" : "Cadastrar"}
                    </Button>
                    <Button color="danger" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ShortcutMessage;