import React, { useState, useEffect } from "react";
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    FormGroup,
    Label,
    Input,
    InputGroup,
    Button,
    InputGroupAddon,
    InputGroupText,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import Widget from "../../components/Widget/Widget.js";
import RegisterClient from "./components/RegisterClient.js";
import RegisterGroup from "./components/RegisterGroup.js";
import { maskDate, phoneMask } from "../../utils/mask.js";
import {
    clientStatus,
    getAllClients,
    getAllGroups,
} from "../../services/Client-Schedule/index.js";
import Swal from "sweetalert2";
import ClientDetails from "./components/ClientDetails.js";
import ClientEdit from "./components/ClientEdit.js";

const Schedule = () => {
    const [list, setList] = useState([]);
    const [showBtnMoreClients, setShowBtnMoreClients] = useState(false);
    const [enableButtons, setEnableButtons] = useState(false);
    const [existingClientIds, setExistingClientIds] = useState(new Set());
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);
    const [selectedClientForEdit, setSelectedClientForEdit] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalGroup, setShowModalGroup] = useState(false);
    const [updateClients, setUpdateClients] = useState(false);
    const [groups, setGroups] = useState([]);
    const [groupsLoaded, setGroupsLoaded] = useState(false);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [form, setForm] = useState({
        dateStart: null,
        dateEnd: null,
        name: null,
        phone: null,
        group: null,
        limit: 30,
        previousLimit: 0,
    });

    const toggleDetailsModal = (clientId) => {
        setIsDetailsModalOpen(!isDetailsModalOpen);
        const selectedClient = list.find((client) => client.id === clientId);
        setSelectedClientDetails(selectedClient);
    };

    const openEditModal = (clientId) => {
        const selectedClient = list.find((client) => client.id === clientId);
        setSelectedClientForEdit(selectedClient);
    };

    const loadGroups = async () => {
        try {
            setLoadingGroups(true);

            const response = await getAllGroups();
            const data = response.data;

            if (data && data.resultSet && data.resultSet.length > 0) {
                const newGroups = data.resultSet;
                setGroups(newGroups);
            }

            setGroupsLoaded(true);
        } catch (error) {
            console.error("Erro ao carregar grupos:", error);
        } finally {
            setLoadingGroups(false);
        }
    };

    const getClients = async (isMore = false) => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => Swal.showLoading(),
        });

        let filterSearch = { ...form };

        if (!isMore) {
            setForm((prevForm) => ({
                ...prevForm,
                previousLimit: 0,
                limit: 30,
            }));

            filterSearch = {
                ...form,
                previousLimit: 0,
                limit: 30,
            };
        } else {
            setForm((prevForm) => ({
                ...prevForm,
                previousLimit: prevForm.limit,
                limit: prevForm.limit + 30,
            }));

            filterSearch = {
                ...form,
                previousLimit: form.limit,
                limit: form.limit + 30,
            };
        }

        try {
            const { data } = await getAllClients(filterSearch);

            Swal.close();
            setEnableButtons(true);

            if (isMore) {
                setList((prevList) => [...prevList, ...data.resultSet]);
            } else {
                setList(data.resultSet);
            }

            setShowBtnMoreClients(data.resultSet.length === filterSearch.limit);
        } catch (error) {
            Swal.close();
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response
                ? "Ocorreu um erro inesperado"
                : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const toggleStatus = async (clientId, active) => {
        const action = active === 1 ? "Inativar" : "Ativar";

        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: `Você tem certeza que deseja ${action} o cliente?`,
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar",
        }).then((confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            clientStatus(clientId, active)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        title: "Operação realizada com sucesso.",
                        confirmButtonText: "Fechar",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: true,
                    });
                    getClients();
                })
                .catch((err) => {
                    const code = !err.response ? "00000" : err.response.data.code;
                    const message = !err.response
                        ? "Ocorreu um erro inesperado"
                        : err.response.data.message;
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `[${code}] ${message}`,
                        showConfirmButton: true,
                    });
                });
        });
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleModalGroup = () => {
        setShowModalGroup(!showModalGroup);
    };

    const handleClientUpdate = () => {
        setUpdateClients((prev) => !prev);
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        getClients();
    }, [updateClients]);


    return (
        <>
            <Row>
                <Col className="pr-grid-col" xs={12} lg={12}>
                    <Card className="border-0 mb-2 mt-3 shadow">
                        <CardHeader className="bg-white border-0">
                            <h4 className="headline-2">Cadastro</h4>
                        </CardHeader>
                        <CardFooter className="border-0 bg-white">
                            <Button
                                color="success"
                                className="mr-2 mt-2"
                                onClick={() => setShowModal(true)}
                            >
                                Cadastrar Cliente
                            </Button>
                            <Button
                                className="btn btn-success btn-md mt-2"
                                disabled={loadingGroups}
                                onClick={() => setShowModalGroup(true)}
                            >
                                Grupos
                            </Button>
                        </CardFooter>
                    </Card>

                    <Card className="border-0 mb-5 mt-3 shadow">
                        <CardHeader className="bg-white border-0">
                            <h4 className="headline-2">Filtro</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>Periodo</Label>
                                        <InputGroup>
                                            <Input
                                                type="text"
                                                value={form.dateStart}
                                                placeholder="--/--/----"
                                                onChange={(ev) => {
                                                    setForm({
                                                        ...form,
                                                        dateStart: maskDate(ev.target.value),
                                                    });
                                                }}
                                            />
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>á</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="text"
                                                value={form.dateEnd}
                                                placeholder="--/--/----"
                                                onChange={(ev) => {
                                                    setForm({
                                                        ...form,
                                                        dateEnd: maskDate(ev.target.value),
                                                    });
                                                }}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>Nome</Label>
                                        <Input
                                            type="text"
                                            value={form.name}
                                            onChange={(ev) => {
                                                setForm({
                                                    ...form,
                                                    name: ev.target.value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>Telefone</Label>
                                        <Input
                                            type="text"
                                            value={form.phone}
                                            onChange={(ev) => {
                                                setForm({
                                                    ...form,
                                                    phone: phoneMask(ev.target.value),
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3">
                                    <FormGroup>
                                        <Label>Grupo</Label>
                                        {loadingGroups ? (
                                            <p className="text-primary">Carregando grupos...</p>
                                        ) : (
                                            <select
                                                className="form-control"
                                                value={form.group || ''}
                                                onChange={(ev) => {
                                                    setForm({
                                                        ...form,
                                                        group: ev.target.value || null,
                                                    });
                                                }}
                                            >
                                                <option value="">Selecione...</option>
                                                {groups.map((group) => (
                                                    <option key={group.id} value={group.id}>
                                                        {group.name}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="border-0 bg-white">
                            <Button
                                color="primary"
                                className="mr-2 mt-2"
                                onClick={() => {
                                    getClients(false);
                                }}
                            >
                                Listar
                            </Button>

                        </CardFooter>
                        <RegisterClient
                            isOpen={showModal}
                            toggleModal={toggleModal}
                            onUpdateClients={handleClientUpdate}
                        />
                        <RegisterGroup
                            isOpen={showModalGroup}
                            toggleModal={toggleModalGroup}
                            execute={() => {
                                loadGroups();
                            }}
                        />
                    </Card>

                    <Row className="gutter">
                        <Col>
                            <Widget>
                                <Card className="border-0">
                                    <CardHeader className="border-0 bg-white">
                                        <Row>
                                            <Col md="9" xl="9">
                                                <div className="">
                                                    <div className="headline-2">Lista de Clientes</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="widget-table-overflow text-center">
                                            {list.length > 0 ? (
                                                <>
                                                    <Table
                                                        className={`table-striped table-borderless table-hover`}
                                                        responsive
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th className="w-10">Data de Cadastro</th>
                                                                <th className="w-15">Nome</th>
                                                                <th className="w-10">Documento</th>
                                                                <th className="w-10">Data de Nascimento</th>
                                                                <th className="w-15">Status</th>
                                                                <th className="w-10">Opções</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                list.map((client, key) => (
                                                                    <tr key={key}>
                                                                        <td>
                                                                            <p>
                                                                                <strong>{client.createdAt}</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p>
                                                                                <strong className="text">
                                                                                    {client.name}
                                                                                </strong>
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p>
                                                                                <strong className="text">
                                                                                    {client.document}
                                                                                </strong>
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p>
                                                                                <strong className="text">
                                                                                    {client.birthDate}
                                                                                </strong>
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            {Number(client.active) === 1 ? (
                                                                                <span className="badge badge-success">
                                                                                    ATIVO
                                                                                </span>
                                                                            ) : (
                                                                                <span className="badge badge-danger">
                                                                                    INATIVO
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle
                                                                                    caret
                                                                                    style={{ color: "black" }}
                                                                                >
                                                                                    OPÇÕES
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            toggleDetailsModal(client.id);
                                                                                        }}
                                                                                    >
                                                                                        Informações
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() => {
                                                                                            openEditModal(client.id);
                                                                                        }}
                                                                                    >
                                                                                        Editar
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        onClick={() =>
                                                                                            toggleStatus(
                                                                                                client.id,
                                                                                                Number(client.active) === 1
                                                                                                    ? 0
                                                                                                    : 1
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {Number(client.active) === 0
                                                                                            ? "Ativar"
                                                                                            : "Inativar"}
                                                                                    </DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                        {showBtnMoreClients && (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6}>
                                                                        <Button
                                                                            className="w-100"
                                                                            onClick={() => {
                                                                                getClients(true);
                                                                            }}
                                                                        >
                                                                            Carregar mais...
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                </>
                                            ) : (
                                                <div className="alert alert-secondary">
                                                    <h4 className="text-dark text-center">
                                                        Não possuem registros no momento.
                                                    </h4>
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                    <ClientDetails
                                        isOpen={isDetailsModalOpen}
                                        toggleModal={toggleDetailsModal}
                                        client={selectedClientDetails}

                                    />
                                    {selectedClientForEdit && (
                                        <ClientEdit
                                            isOpen={Boolean(selectedClientForEdit)}
                                            toggleModal={() => setSelectedClientForEdit(null)}
                                            client={selectedClientForEdit}
                                            onUpdateClients={getClients}
                                        />
                                    )}
                                </Card>
                            </Widget>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Schedule;
