import React, { useEffect, useState } from 'react'
import { createOnboarding } from "../../services/Onboarding";
import {
    Row,
    Col,
    Button,
    FormText,
    Progress,
    Card,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Swal from 'sweetalert2';
import "./assets/Onboarding.css"
import getQuestions from './Components/questions';

function Onboarding() {

    const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentQuestion, SetCurrentQuestion] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [state, setState] = useState({
        hasEmployee: '', minMaxEmployee: '', whyLookingThis: '', othersWhyLookingThis: '',
        sendCampaign: '', needFinance: '', useOtherSystem: '', otherSystem: ''
    });
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

    const calculateProgress = (updatedState) => {
        const totalQuestions = 8;
        let answeredQuestions = 0;

        if (updatedState.hasEmployee !== '') answeredQuestions++;
        if (updatedState.minMaxEmployee !== '' || updatedState.hasEmployee === '0') answeredQuestions++;
        if (updatedState.whyLookingThis !== '') answeredQuestions++;
        if (updatedState.othersWhyLookingThis !== '' || updatedState.whyLookingThis !== 'others') answeredQuestions++;
        if (updatedState.sendCampaign !== '') answeredQuestions++;
        if (updatedState.needFinance !== '') answeredQuestions++;
        if (updatedState.useOtherSystem !== '') answeredQuestions++;
        if (updatedState.otherSystem !== '' || updatedState.useOtherSystem === '0') answeredQuestions++;

        return (answeredQuestions / totalQuestions) * 100;
    };

    const forwardQuestion = () => {
        if (currentQuestion < questions.length - 1) {
            SetCurrentQuestion(currentQuestion + 1);
        }
    };

    const backQuestion = () => {
        if (currentQuestion > 0) {
            SetCurrentQuestion(currentQuestion - 1);
        }
    };

    const changeOnboarding = (event) => {
        const { name, value } = event.target;

        setState((prevState) => {
            let updatedState = {
                ...prevState,
                [name]: value,
            };

            if (name === 'hasEmployee' && value === '0') {
                updatedState.minMaxEmployee = '';
            }
            if (name === 'whyLookingThis' && value === 'others') {
                updatedState.othersWhyLookingThis = '';
            }
            if (name === 'useOtherSystem' && value === '0') {
                updatedState.otherSystem = '';
            }

            return updatedState;
        });
    };

    const onCreate = async (event) => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        event.preventDefault();
        setIsSubmitting(true);

        try {
            await createOnboarding(state);

            const updatedUser = { ...user, requiredOnboarding: 0 };
            localStorage.setItem("data_user_izichat_app", JSON.stringify(updatedUser));

            setIsSubmitting(false);
            history.push("/admin/dashboard");

        } catch (error) {
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
            }).then(() => setIsSubmitting(false)); 
        
            }
        };



        const questions = getQuestions(state, changeOnboarding, onCreate, isSubmitting);

        useEffect(() => {
            setProgress(calculateProgress(state));

            switch (currentQuestion) {
                case 0:
                    setIsNextButtonEnabled(true);
                    break;
                case 1:
                    setIsNextButtonEnabled(
                        state.hasEmployee === '0' || state.minMaxEmployee !== ''
                    );
                    break;
                case 2:
                    setIsNextButtonEnabled(
                        state.whyLookingThis !== '' &&
                        (state.whyLookingThis !== 'others' || state.othersWhyLookingThis !== '')
                    );
                    break;
                case 3:
                    setIsNextButtonEnabled(
                        state.sendCampaign === '0' || state.sendCampaign === '1'
                    );
                    break;
                case 4:
                    setIsNextButtonEnabled(
                        state.needFinance === '0' || state.needFinance === '1'
                    );
                    break;
                case 5:
                    setIsNextButtonEnabled(
                        state.useOtherSystem === '0' || state.otherSystem !== ''
                    );
                    break;
                default:
                    setIsNextButtonEnabled(false);
            }
        }, [state, currentQuestion]);

        return (
            <div className='progress-div d-flex flex-column'>
                <Card className="card-div d-flex align-items-center flex-column">
                    <img src={Logo} alt="Logo" className="logo-chat" />
                    <Row className="card-div d-flex align-items-center justify-content-center">
                        <Col xs={10} lg={12} className="d-flex flex-column align-items-center justify-content-center">
                            <div className="w-100">
                                <FormText className="formText">{questions[currentQuestion].question}
                                </FormText>
                            </div>
                            {currentQuestion !== 6 && (
                                <div className="bg-widget d-flex flex-sm-row flex-column-reverse justify-content-center">
                                    {currentQuestion !== 0 && currentQuestion !== 1 && (
                                        <Button
                                            className="mx-1 my-3 border-1 text-light buttons-size"
                                            style={{ backgroundColor: '#009582' }}
                                            onClick={backQuestion}
                                            disabled={currentQuestion === 0}>
                                            Voltar
                                        </Button>
                                    )}

                                    <Button
                                        className="mx-1 my-3 border-1 text-light buttons-size"
                                        style={{ backgroundColor: '#009582' }}
                                        onClick={forwardQuestion}
                                        disabled={!isNextButtonEnabled || currentQuestion === questions.length - 1}>
                                        Continuar
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                    {currentQuestion !== 0 && currentQuestion !== 6 && (
                        <FormText className="formText text-center p-3">Campos com<span className="text-danger"> * </span>são obrigatorios.</FormText>
                    )}
                    {currentQuestion !== 6 && (
                        <Progress className='w-100' value={progress} />
                    )}
                </Card>
            </div>
        );
    }

    export default Onboarding