import api from "../api";

export const getAllInstance = async () => {
  return await api.get('/api/v1/whatsapp/qrcode');
};

export const getInstancesById = async (instanceId) => {
  return await api.get('/api/v1/whatsapp/qrcode/id',{
    params: {
      instanceId: instanceId
    }
  });
};

export const setIntervalInstance = async (instace, interval) => {
  return await api.put(`/api/v1/whatsapp/instance/interval/${instace}/${interval}`);
};

export const listAllInstances = async () => {
  return await api.get('/api/v1/whatsapp/instance');
};

export const agendSendMessage = async (data) => {
  return await api.post('/api/v1/whatsapp/agend/message', data);
};

export const getAllMessages = async (filter) => {
  return await api.get('/api/v1/whatsapp/message', {
    params: filter
  });
};

export const getCampaignList = async (filter) => {
  return await api.get('/api/v1/whatsapp/campaign', {
    params: filter
  });
};

export const getMessageByStatus = async (filter) => {
  return await api.get("/api/v1/whatsapp/analytics/graphStatus", {
    params: filter
  });
}

export const getMessageByDayAndStatus = async (filter) => {
  return await api.get('/api/v1/whatsapp/analytics/graphDayStatus', {
    params: filter
  });
}

export const getMessageByInstanceAndStatus = async (filter) => {
  return await api.get('/api/v1/whatsapp/analytics/graphInstanceStatus', {
    params: filter
  });
}

export const postResendCampaign = async (campaignId) => {
  return await api.post(`/api/v1/whatsapp/campaign/resend/${campaignId}`);
};

export const getCampaignInfoService = async (campaignId) => {
  return await api.get(`/api/v1/whatsapp/campaign/info/${campaignId}`);
};

export const getCampaignNumbers = async (userId) => {
  return await api.get(`/api/v1/whatsapp/campaign/info/numbers/${userId}`);
};

export const stopSendMessage = async (ids) => {
  return await api.put('/api/v1/whatsapp/stop/message', {
    messagesId: ids
  });
};

export const stopSendMessageByDate = async (filter) => {
  return await api.put('/api/v1/whatsapp/stop/message', filter);
};

export const logoutInstance = async (instance) => {
  return await api.post(`/api/v1/whatsapp/logout/${instance}`);
};

export const getResumeStatusMessage = async (filter) => {
  return await api.get('/api/v1/whatsapp/resume/status', {
    params: filter,
  });
};

export const listAllInstanceService = async () => {
  return await api.get('/api/v1/instance');
};

export const createTimeAndInterval = async (minTimeExecute, maxTimeExecute, instanceId, interval) => {
  return await api.post("/api/v1/time-and-interval", { minTimeExecute, maxTimeExecute, instanceId, interval });
};

export const postCancelCampaign = async (campaignId) => {
  return await api.delete(`/api/v1/whatsapp/campaign/cancel/${campaignId}`);
};

export const postResendMessage = async (messageId) => {
  return await api.post(`/api/v1/whatsapp/message/resend/${messageId}`);
};

export const postNewContacts = async (campaignId, data) => {
  return await api.post(`/api/v1/whatsapp/newContacts/${campaignId}`, data);
};
