import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Row, Card, CardHeader, Label, FormGroup, Input, Button, CardBody, CardFooter } from "reactstrap";
import { getConfig, postConfig, updateConfig } from "../../services/Chat-Config";
import Swal from "sweetalert2";
import AlertSounds from "./AlertSounds";

const ChatConfig = () => {
    const [editMode, setEditMode] = useState(false);
    const [configData, setConfigData] = useState(null);
    const [editedConfig, setEditedConfig] = useState({
        alertSounds: "",
        dateStart: "",
        dateEnd: "",
        exitMessage: "",
        welcomeMessage: "",
        standByMessage: ""
    });

    const handlePlaySound = () => {
        if (editedConfig.alertSounds) {
            const audio = new Audio(editedConfig.alertSounds.value);
            audio.play();
        }
    };

    const handleSave = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const data = {
                alertSound: editedConfig.alertSounds.value,
                dateStart: editedConfig.dateStart,
                dateEnd: editedConfig.dateEnd,
                msgExit: editedConfig.exitMessage,
                msgWelcome: editedConfig.welcomeMessage,
                msgStandBy: editedConfig.standByMessage,
            };

            const response = await postConfig(data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "Configurações salvas com sucesso!",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
            }

            setEditMode(false)
            handleList();
        } catch (error) {
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleEdit = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const data = {
                alertSound: editedConfig.alertSounds.value,
                dateStart: editedConfig.dateStart,
                dateEnd: editedConfig.dateEnd,
                msgExit: editedConfig.exitMessage,
                msgWelcome: editedConfig.welcomeMessage,
                msgStandBy: editedConfig.standByMessage,
            };

            const response = await updateConfig(data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Sucesso!",
                    text: "Configurações editadas com sucesso!",
                    confirmButtonText: "OK",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
            }

            setEditMode(false)
            handleList();
        } catch (error) {
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleList = async () => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = await getConfig();
            const fetchedConfigData = response.data.resultSet[0];

            if (!fetchedConfigData) {
                setEditMode(false);
            } else {
                const selectedSound = Object.values(AlertSounds).find(
                    (sound) => sound.value === fetchedConfigData.urlSound
                );

                setEditedConfig({
                    ...editedConfig,
                    alertSounds: selectedSound,
                    dateStart: fetchedConfigData.dateStart,
                    dateEnd: fetchedConfigData.dateEnd,
                    exitMessage: fetchedConfigData.msgExit,
                    welcomeMessage: fetchedConfigData.msgWelcome,
                    standByMessage: fetchedConfigData.msgStandBy,
                });
            }

            setConfigData(response.data.resultSet);

            Swal.close();
        } catch (error) {
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
                showConfirmButton: true,
            });
        }
    };

    const handleCancel = () => {
        setEditedConfig({
            alertSounds: "",
            dateStart: "",
            dateEnd: "",
            exitMessage: "",
            welcomeMessage: "",
            standByMessage: ""
        });
        handleList();
    }

    useEffect(() => {
        handleList();
    }, []);

    return (
        <>
            <Row>
                <Col className="pr-grid-col" xs={12} lg={12}>
                    <Card className="mb-5 mt-3 shadow">
                        <CardHeader className="border-0 bg-white pb-0">
                            <h2 className="headline-2 pt-3">Chat - Configurações</h2>
                        </CardHeader>
                        <CardBody className="border-0">
                            <Card className="p-0">
                                <CardHeader className="bg-white border-0">
                                    <h2 className="headline-3">Personalizar</h2>
                                </CardHeader>
                                <CardBody className="py-0 d-flex flex-md-row flex-column">
                                    <FormGroup className="col-12 col-md-6 p-0 mr-1">
                                        <Label>Sons de Alerta</Label>
                                        <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start">
                                            <Select
                                                options={Object.values(AlertSounds)}
                                                value={editedConfig.alertSounds}
                                                onChange={(selectedOption) => setEditedConfig({ ...editedConfig, alertSounds: selectedOption })}
                                                className="p-0 mr-md-2 col-12"
                                                isDisabled={!editMode}
                                                placeholder="Selecione..."
                                            />
                                            {editedConfig.alertSounds && (
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mt-md-0 mt-2 d-flex align-items-center justify-content-center"
                                                    onClick={handlePlaySound}
                                                >
                                                    <i className="fa fa-play mr-1"
                                                        style={{
                                                            fontSize: "12px"
                                                        }} /> Escutar
                                                </Button>
                                            )}
                                        </div>
                                    </FormGroup>

                                </CardBody>
                            </Card>

                            <Card className="p-0 mt-3">
                                <CardHeader className="bg-white border-0">
                                    <h2 className="headline-3">Horários de Atendimento</h2>
                                </CardHeader>
                                <CardBody className="py-0 d-flex flex-md-row flex-column">
                                    <FormGroup className="col-12 col-md-6 p-0 mr-1">
                                        <Label>Início</Label>
                                        <Input
                                            type="time"
                                            value={editedConfig.dateStart}
                                            onChange={(e) => setEditedConfig({ ...editedConfig, dateStart: e.target.value })}
                                            disabled={!editMode}
                                        />
                                    </FormGroup>
                                    <FormGroup className="col-12 col-md-6 p-0 mt-md-0">
                                        <Label>Término </Label>
                                        <Input
                                            type="time"
                                            value={editedConfig.dateEnd}
                                            onChange={(e) => setEditedConfig({ ...editedConfig, dateEnd: e.target.value })}
                                            disabled={!editMode}
                                        />
                                    </FormGroup>
                                </CardBody>
                            </Card>

                            <Card className="p-0 mt-3">
                                <CardHeader className="bg-white border-0">
                                    <h2 className="headline-3">Mensagens automáticas</h2>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <FormGroup className="mb-3">
                                        <Label>Fora do Horário de Atendimento</Label>
                                        <Input
                                            type="textarea"
                                            value={editedConfig.standByMessage}
                                            onChange={(e) => setEditedConfig({ ...editedConfig, standByMessage: e.target.value })}
                                            disabled={!editMode}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label>Mensagem de boas vindas</Label>
                                        <Input
                                            type="textarea"
                                            value={editedConfig.welcomeMessage}
                                            onChange={(e) => setEditedConfig({ ...editedConfig, welcomeMessage: e.target.value })}
                                            disabled={!editMode}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Mensagem de Finalização</Label>
                                        <Input
                                            type="textarea"
                                            value={editedConfig.exitMessage}
                                            onChange={(e) => setEditedConfig({ ...editedConfig, exitMessage: e.target.value })}
                                            disabled={!editMode}
                                        />
                                    </FormGroup>

                                </CardBody>
                            </Card>
                            <div className="bg-white border-0 d-flex justify-content-start flex-md-row flex-column mt-4">
                                {editMode ? (
                                    <Button color="secondary" className="mr-md-2 mr-0 mb-md-0 mb-2"
                                        onClick={() => {
                                            setEditMode(false)
                                            handleCancel()
                                        }}
                                        style={{ maxWidth: "150px" }}
                                    >
                                        Cancelar
                                    </Button>
                                ) : (
                                    <Button color="primary"
                                        onClick={() => setEditMode(true)}
                                        style={{ maxWidth: "150px" }}
                                    >
                                        Editar
                                    </Button>
                                )}
                                {editMode && (
                                    <Button color="primary"
                                        onClick={configData.length > 0 ? handleEdit : handleSave}
                                        style={{ maxWidth: "150px" }}
                                    >
                                        {configData.length > 0 ? "Editar" : "Salvar"}
                                    </Button>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ChatConfig