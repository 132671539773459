import React from 'react';
import {
    Container,
    FormText,
    Input,
    Button,
} from 'reactstrap';

const getQuestions = (state, changeOnboarding, onCreate, isSubmitting) => [
    {
        id: "0", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-between py-5">
                    <p className="auth-header mb-4">Bem vindo ao Izichat, para finalizar o seu cadastro siga as etapas abaixo:</p>
                </div>
            </Container>
        )
    },
    {
        id: "1", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <FormText className="text-input text-center">Possui funcionários?<span className="text-danger">*</span>
                </FormText>
                <div className='radio-group d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="hasEmployeeYes"
                            className="form-check"
                            value="1"
                            onChange={changeOnboarding}
                            type="radio"
                            name="hasEmployee"
                            checked={state.hasEmployee === '1'}
                        />
                        <label className="form-check-label label-large" htmlFor="hasEmployeeYes">
                            Sim
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="hasEmployeeNo"
                            className="form-check"
                            value="0"
                            onChange={changeOnboarding}
                            type="radio"
                            name="hasEmployee"
                            checked={state.hasEmployee === '0'}
                        />
                        <label className="form-check-label label-large" htmlFor="hasEmployeeNo">
                            Não
                        </label>
                    </div>
                </div>
                <div>
                    {state.hasEmployee === "1" && (
                        <div>
                            <div className="d-flex justify-content-between">
                                <FormText className="text-input text-center">Qual é a quantidade de funcionários?<span className="text-danger">*</span>
                                </FormText>
                            </div>
                            <div>
                                <select
                                    id="minMaxEmployee"
                                    className="form-control input-size"
                                    value={state.minMaxEmployee}
                                    onChange={(event) => changeOnboarding(event)}
                                    required
                                    name="minMaxEmployee"
                                >
                                    <option value="">Selecione...</option>
                                    <option value="1-5">De 1 a 5</option>
                                    <option value="6-10">De 6 a 10</option>
                                    <option value="11-20">de 11 a 20</option>
                                    <option value="21-50">de 21 a 50</option>
                                    <option value="51-9999">Mais de 50</option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        )
    },
    {
        id: "2", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-between">
                    <FormText className="text-input text-center">Porque esta buscando uma ferramenta como o Izichat?<span className="text-danger">*</span>
                    </FormText>
                </div>
                <div className='w-100'>
                    <select
                        id="whyLookingThis"
                        className="form-control pl-3"
                        value={state.whyLookingThis}
                        onChange={(event) => changeOnboarding(event)}
                        required
                        name="whyLookingThis"
                    >
                        <option value="">Selecione...</option>
                        <option value="Melhorar relacionamento com o cliente">Melhorar relacionamento com o cliente</option>
                        <option value="Realizar atendimento suporte">Realizar atendimento suporte</option>
                        <option value="others">Outros</option>
                    </select>
                </div>
                <div className='w-100'>
                    {state.whyLookingThis === "others" && (
                        <div className='w-100 mt-3'>
                            <div className="d-flex justify-content-center">
                                <FormText className="text-input text-center">Digite aqui o motivo<span className="text-danger">*</span>
                                </FormText>
                            </div>
                            <div>
                                <Input
                                    id="othersWhyLookingThis"
                                    className="pl-3"
                                    value={state.othersWhyLookingThis}
                                    onChange={(event) => changeOnboarding(event)}
                                    required
                                    type="text"
                                    name="othersWhyLookingThis"
                                    placeholder="Digite aqui o motivo."
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        )
    },

    {
        id: "3", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <FormText className="text-input text-center">Você realiza envio de campanhas para seus clientes?
                    <span className="text-danger">*</span>
                </FormText>
                <div className='radio-group d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="sendCampaignYes"
                            className="form-check"
                            value="1"
                            onChange={changeOnboarding}
                            type="radio"
                            name="sendCampaign"
                            checked={state.sendCampaign === '1'}
                        />
                        <label className="form-check-label label-large" htmlFor="sendCampaignYes">
                            Sim
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="sendCampaignNo"
                            className="form-check"
                            value="0"
                            onChange={changeOnboarding}
                            type="radio"
                            name="sendCampaign"
                            checked={state.sendCampaign === '0'}
                        />
                        <label className="form-check-label label-large" htmlFor="sendCampaignNo">
                            Não
                        </label>
                    </div>
                </div>
            </Container>
        )
    },

    {
        id: "4", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <FormText className="text-input text-center">Precisa controlar seu financeiro?
                    <span className="text-danger">*</span>
                </FormText>
                <div className='radio-group d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="needFinanceYes"
                            className="form-check"
                            value="1"
                            onChange={changeOnboarding}
                            type="radio"
                            name="needFinance"
                            checked={state.needFinance === '1'}
                        />
                        <label className="form-check-label label-large" htmlFor="needFinanceYes">
                            Sim
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="needFinanceNo"
                            className="form-check"
                            value="0"
                            onChange={changeOnboarding}
                            type="radio"
                            name="needFinance"
                            checked={state.needFinance === '0'}
                        />
                        <label className="form-check-label label-large" htmlFor="needFinanceNo">
                            Não
                        </label>
                    </div>
                </div>
            </Container>
        )
    },

    {
        id: "5", question: (
            <Container className="container-input d-flex flex-column align-items-center justify-content-center">
                <FormText className="text-input text-center">Já utilizou outra ferramenta como o Izichat?
                    <span className="text-danger">*</span>
                </FormText>
                <div className='radio-group d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="useOtherSystemYes"
                            className="form-check"
                            value="1"
                            onChange={changeOnboarding}
                            type="radio"
                            name="useOtherSystem"
                            checked={state.useOtherSystem === '1'}
                        />
                        <label className="form-check-label label-large" htmlFor="useOtherSystemYes">
                            Sim
                        </label>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Input
                            id="useOtherSystemNo"
                            className="form-check"
                            value="0"
                            onChange={changeOnboarding}
                            type="radio"
                            name="useOtherSystem"
                            checked={state.useOtherSystem === '0'}
                        />
                        <label className="form-check-label label-large" htmlFor="useOtherSystemNo">
                            Não
                        </label>
                    </div>
                </div>
                <div className='w-100'>
                    {state.useOtherSystem === "1" && (
                        <div className='w-100'>
                            <div className="d-flex justify-content-center">
                                <FormText className="text-input text-center">Digite aqui o nome da ferramenta<span className="text-danger">*</span>
                                </FormText>
                            </div>
                            <div>
                                <Input
                                    id="otherSystem"
                                    className="pl-3"
                                    value={state.otherSystem}
                                    onChange={(event) => changeOnboarding(event)}
                                    required
                                    type="text"
                                    name="otherSystem"
                                    placeholder="Digite aqui o nome da ferramenta."
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        )

    },
    {
        id: "6", question: (
            <Container className="container-feedback">
                <div className="flex-column d-flex align-items-center justify-content-between py-5">
                    <p className="auth-header mb-4 header-large">Obrigado pelo feedback!</p>
                    <form onSubmit={(event) => onCreate(event)}>
                        <div className="bg-widget d-flex justify-content-center">
                            <Button
                                className="mx-1 my-3 border-1 text-light buttons-size"
                                style={{ backgroundColor: '#009582' }}
                                disabled={isSubmitting}
                                onClick={onCreate}
                            >
                                Sair
                            </Button>
                        </div>
                    </form>
                </div>
            </Container>
        )

    }
];

export default getQuestions;
