import api from "../api";

export const registerShortcutMessages = async (data) => {
  return await api.post('/api/v1/shortcut-messages', data);
}

export const getRegisterShortcutMessages= async () => {
  return await api.get('/api/v1/shortcut-messages');
}

export const setStatusShortcutMessages = async (messageId, newStatus) => {
  return await api.put(`/api/v1/shortcut-messages/status/${messageId}`, newStatus);
}

export const updateShortcutMessages = async (messageId, data) => {
  return await api.put(`/api/v1/shortcut-messages/${messageId}`, data);
}