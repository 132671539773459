import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Alert } from 'reactstrap';
import { getBillsByIncomeAndExpenses } from 'services/BillsReport';
import Swal from 'sweetalert2';

const IncomeAndExpensesChart = ({ filter }) => {
  const [listIncomeAndExpenses, setListIncomeAndExpenses] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleListIncomeAndExpenses = () => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getBillsByIncomeAndExpenses(filter)
      .then((res) => {
        setListIncomeAndExpenses(res.data.resultSet);
        setLoading(false);
        setTimeout(() => {
          Swal.close();
        }, 6000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };
  useEffect(() => {
    handleListIncomeAndExpenses();
  }, [filter]);

  const series = [
    {
      name: 'Pagamentos',
      data: listIncomeAndExpenses.map(item => parseFloat(item.totalPayment))
    },
    {
      name: 'Recebimentos',
      data: listIncomeAndExpenses.map(item => parseFloat(item.totalReceive))
    }
  ];

  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => {
        return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      }
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'date',
      categories: listIncomeAndExpenses.map(item => item.createdAt),
      labels: {
        format: 'DD/MM/YY'
      }
    },
    tooltip: {
      x: {
        format: 'DD/MM/YY'
      },
      y: {
        formatter: (value) => {
          return `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
      }
    },
    colors: ['#FF5733', '#33FF57']
  };

  if (loading) {
    return <div>Carregando...</div>;
  }
  if (listIncomeAndExpenses.length <= 0) {
    return <Alert className="bg-info">Sem Dados...</Alert>;
  }

  return (
    <div>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default IncomeAndExpensesChart;
