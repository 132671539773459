import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Alert,
    Label,
    FormGroup,
    Input,
    InputGroupAddon,
    Button,
    InputGroup,
    CardFooter
} from "reactstrap";
import Select from 'react-select';
import { getAllGroups } from "../../services/Client-Schedule";
import Swal from "sweetalert2";
import { registerClient } from "../../services/SimplifiedRegister";

const SimplifiedRegister = () => {
    const [numbersPhone, setNumbersPhone] = useState("");
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [client, setClient] = useState({
        numbers: [],
        groups: [],
        type: "whatsapp"
    });

    const handleAddPhone = async () => {
        const numbers = client.numbers;
        numbersPhone.split("\n").map((line) =>
            line.split("\t").map((value) => {
                numbers.push(value);
            })
        );
        setClient({
            ...client,
            numbers: numbers,
        });

        setNumbersPhone("");
    };


    const handleChange = async (ev) => {
        if (ev.target.name === "number") {
            setNumbersPhone(ev.target.value);
        }
    };

    const handleRemovePhone = (index) => {
        const updatedNumbers = [...client.numbers];
        updatedNumbers.splice(index, 1);
        setClient((prevClient) => ({
            ...prevClient,
            numbers: updatedNumbers,
        }));
    };

    const handleRemoveAllPhones = () => {
        setClient({ ...client, numbers: [] });
    };

    const getGroup = async () => {
        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde.",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
        try {
            const response = await getAllGroups().then((res) => {
                Swal.close();
                return res;
            });
            Array.isArray(response.data.resultSet);
            setGroupList([...response.data.resultSet]);
        } catch (error) {
            Swal.close();
            console.error("Erro ao buscar a lista de grupos", error);
        }
    };

    const handleSubmit = async () => {
        if (
            !client.numbers.length > 0
        ) {
            Swal.fire({
                icon: "warning",
                title: "Aviso",
                text: "Por favor, o campo de telefones é obrigatório!",
            });
            return;
        }

        Swal.fire({
            title: "Carregando, Por favor aguarde...",
            text: "Por favor aguarde",
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        await registerClient(client)
            .then(() => {
                Swal.close();

                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Cliente Cadastrado com sucesso!",
                });

                setClient({
                    numbers: [],
                    groups: [],
                    type: "whatsapp"
                });

                setNumbersPhone("");
                setSelectedGroup("")
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Erro",
                    text: "Não foi possível finalizar o registro",
                });
            });
    };

    useEffect(() => {
        getGroup();
    }, []);

    return (
        <>
            <Row>
                <Col className="pr-grid-col">
                    <Card className="border-0 mb-2 mt-3 shadow">
                        <CardHeader className="bg-white border-0">
                            <h4 className="headline-2">Cadastro simplificado</h4>
                        </CardHeader>
                        <CardBody className="py-0">
                            <Alert color="secondary" className="text-primary">
                                <p>No campo <strong>Números de WhatsApp</strong>, você pode cadastrar os <strong>números</strong> juntamente com o <strong>nome do cliente</strong>.<strong> Ex: 44999665533;JOÃO</strong>.</p>
                                <p>Caso não informe o nome, os números serão cadastrados <strong>normalmente</strong>.</p>
                            </Alert>
                            <FormGroup>
                                <Label for="number">Números de WhatsApp*</Label>
                                <InputGroup>
                                    <textarea
                                        id="number"
                                        name="number"
                                        value={numbersPhone}
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                    ></textarea>
                                    <InputGroupAddon addonType="append">
                                        <Button color="secondary" onClick={handleAddPhone}>
                                            ADICIONAR NÚMEROS
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                <div>
                                    {client.numbers.length > 0 ? (
                                        <>
                                            <div className="mt-3 rounded-left w-100 p-0" style={{ maxHeight: "350px", overflow: "auto" }}>
                                                <div className="m-0 alert alert-secondary rounded-0">
                                                    <div className="d-flex flex-lg-row flex-column mb-4">
                                                        <h4 className="text-dark m-0 mr-2 mb-lg-0 mb-2">Números adicionados</h4>
                                                        <div className="badge badge-success d-flex align-items-center" style={{ maxWidth: "120px" }}>Quantidade: {client.numbers.length}</div>
                                                    </div>
                                                    <div className="d-flex flex-wrap h-auto" style={{ gap: "10px" }}>
                                                        {client.numbers.map((number, index) => (
                                                            <div className="d-flex align-itens-center">
                                                                <span key={index} className="badge badge-success rounded-0">
                                                                    {number}
                                                                </span>
                                                                <button
                                                                    className="btn-danger border-0"
                                                                    style={{ padding: "2px 5px", fontSize: "12px", textAlign: "center", display: "flex", justifyContent: "center" }}
                                                                    onClick={() => handleRemovePhone(index)}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-danger mt-3"
                                                onClick={handleRemoveAllPhones}
                                            >
                                                Remover Todos
                                            </button>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Selecione um ou mais grupos</Label>
                                <Select
                                    isMulti
                                    name="groups"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Selecione..."
                                    value={selectedGroup}
                                    onChange={(selectedOptions) => {
                                        setSelectedGroup(selectedOptions);
                                        const selectedGroupIds = selectedOptions.map(option => parseInt(option.value));
                                        setClient(prevClient => ({
                                            ...prevClient,
                                            groups: selectedGroupIds,
                                        }));
                                    }}
                                    options={groupList.map((group) => ({
                                        value: group.id,
                                        label: group.name,
                                    }))}
                                />
                            </FormGroup>
                        </CardBody>
                        <CardFooter className="border-0 bg-white">
                            <Button
                                className="btn btn-lg btn-success"
                                onClick={handleSubmit}
                            >
                                Cadastrar
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default SimplifiedRegister;
