import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  InputGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import QRCode from "qrcode.react";
import {
  getAllInstance,
  logoutInstance,
  createTimeAndInterval,
  getInstancesById,
} from "../../services/Instance";
import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";
import s from "./Dashboard.module.scss"
import "./Dashboard.module.css"

const Instance = ({ customGrow }) => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpenModalQR, setIsOpenModalQR] = useState(false);
  const [isOpenModalConfig, setIsOpenModalConfig] = useState(false);
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [interval, setInterval] = useState(null);
  const [timeSelect, setTimeSelect] = useState({
    minTimeExecute: null,
    maxTimeExecute: null,
  });

  const updateInstance = async () => {
    Swal.fire({
      title: "Carregando....",
      text: "Por favor aguarde!",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await getAll();
    Swal.close();
  };

  const handleSaveSettings = async (instanceId) => {
    if (timeSelect.minTimeExecute === timeSelect.maxTimeExecute) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Os horários de início e término não podem ser iguais.",
      });
      return;
    }

    const result = await Swal.fire({
      title: "Confirmar",
      text: "Deseja realmente salvar as configurações?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, salvar",
      cancelButtonText: "Cancelar",
    });

    if (!result.isConfirmed) {
      return;
    }

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await createTimeAndInterval(
        timeSelect.minTimeExecute,
        timeSelect.maxTimeExecute,
        instanceId,
        interval
      );

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Configurações salvas com sucesso!",
      });

      resetModalConfig();

    } catch (err) {
      const code = err.response?.data?.code || "Unknown Error";
      const message = err.response?.data?.message || "Erro ao salvar configurações.";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const resetModalQR = () => {
    setIsOpenModalQR(false);
    getAll();
  };

  const resetModalConfig = () => {
    setIsOpenModalConfig(false);
  };

  const getAll = async () => {
    await getAllInstance().then((res) => {
      if (!res.data.status) return;
      setInstances(res?.data?.resultSet ?? []);
    });
  };

  const handleGetInstancesById = async (id) => {
    Swal.fire({
      title: "Carregando....",
      text: "Por favor aguarde!",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await getInstancesById(id).then((res) => {

        if (!res.data.status) return;
        setSelectedInstance(res?.data?.resultSet ?? []);
        Swal.close();

      });

    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const logout = async (instance) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja desconectar este número?",
      showCancelButton: true,
      cancelButtonText: "NÃO, SAIR",
      confirmButtonText: "SIM, DESCONECTAR",
    }).then(async (confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Saindo....",
        text: "Por favor aguarde!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await logoutInstance(instance)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Número desconectado",
          });

          getAll();

        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Não foi possível desconectar o número",
          });
        });
    });
  };

  const handleOpenQRModal = async (id) => {
    await handleGetInstancesById(id);
    setIsOpenModalQR(true);
  };

  const handleOpenConfigModal = (instance) => {
    setSelectedInstance(instance);
    setTimeSelect({
      minTimeExecute: instance.minTimeExecute,
      maxTimeExecute: instance.maxTimeExecute,
    });
    setInterval(instance.minuteSendMessage);
    setIsOpenModalConfig(true);
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    let timeoutId;
    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        history.push("/admin/dashboard");
      }, 120000);
    };

    const resetTimer = () => {
      window.addEventListener("mousemove", resetTimeout);
      window.addEventListener("keypress", resetTimeout);
      resetTimeout();
    };

    resetTimer();

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keypress", resetTimeout);
    };
  }, [history]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let timeout;

    const fetchInstanceData = async () => {
      if (selectedInstance?.id) {
        const res = await getInstancesById(selectedInstance.id);

        if (!res.data.status) return;

        const updatedInstance = res.data.resultSet;

        if (updatedInstance) {
          setSelectedInstance(updatedInstance);

          if (updatedInstance.isRead) {
            resetModalQR();
            clearTimeout(timeout);
            return;
          }
        }
      }

      if (isOpenModalQR) {
        timeout = setTimeout(fetchInstanceData, 10000);
      }

    };


    if (isOpenModalQR) {
      fetchInstanceData();

      return () => clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [isOpenModalQR, selectedInstance?.id]);

  return (
    <div>
      <Button color="primary" className="mb-4" onClick={updateInstance}>Recarregar instancias</Button>
      <Row>
        <Col className={`${s.cardContainer} pr-grid-col d-flex align-items-stretch justify-content-md-start justify-content-center flex-wrap`} xs={12} lg={12}>
          {instances.length > 0 ? (
            instances.map((instance, key) => (
              <>
                <div className={`${customGrow ? s.customGrow : s.divCard} d-flex align-items-stretch`}>
                  <Card className="shadow w-100">
                    <CardHeader className="bg-white border-0">
                      {instance.isRead ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="fa-brands fa-whatsapp" style={{ fontSize: "2rem", color: "#189d0e" }} />
                            <p className="mx-2" color="success" style={{ fontSize: " 1.2rem" }}>{instance.name}</p>
                          </div>
                          <Button
                            className="bg-transparent rounded-circle text-secondary p-3 border-0 d-flex align-items-center justify-content-center"
                            style={{ fontSize: "1.5rem", width: "40px", height: "40px" }}
                            onClick={() => handleOpenConfigModal(instance)}
                          >
                            <i className="fa-solid fa-ellipsis-vertical" />
                          </Button>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <i className="fa-brands fa-whatsapp" style={{ fontSize: "2rem", color: "#189d0e" }} />
                            <p className="mx-2" color="success" style={{ fontSize: " 1.2rem" }}>{instance.name}</p>
                          </div>
                        </>
                      )}
                    </CardHeader>
                    <CardBody className="pt-1 d-flex flex-column justify-content-end">
                      {instance.isRead ? (
                        <Card className="border-0">
                          <CardBody className="p-0">
                            <h3 className="text-success mb-1">Conectado</h3>
                            <div>
                              <p>
                                Nome: <strong>{instance.name}</strong>
                              </p>
                              <p>
                                Número: <strong>{instance.user.phone}</strong>
                              </p>
                            </div>
                            <div className="mt-2">
                              <button
                                className="btn btn-warning w-100 text-white"
                                onClick={() => {
                                  logout(instance.name);
                                }}
                              >
                                DESCONECTAR NÚMERO
                              </button>
                            </div>

                          </CardBody>
                        </Card>
                      ) : (
                        <Card
                          className="d-flex flex-column align-items-center justify-content-center border-0"
                        >
                          <CardBody className="p-0">
                            <Button className="btn-danger mb-3 w-100" style={{ cursor: "default" }}>Desconectado</Button>
                            <Button className="btn-success w-100" onClick={() => handleOpenQRModal(instance.id)}>Conectar WhatsApp</Button>
                          </CardBody>
                        </Card>
                      )}
                    </CardBody>
                  </Card>

                  <Modal isOpen={isOpenModalQR} toggle={resetModalQR} className="modal-dialog-centered" backdrop={true}>
                    <style>{`.modal-backdrop {background-color: rgba(0, 0, 0, 0.6)}`}</style>
                    <ModalHeader toggle={resetModalQR} className="border-0 pb-0"></ModalHeader>
                    <ModalBody className="d-flex flex-column align-items-center justify-content-center mb-4 pt-0">
                      {selectedInstance && selectedInstance.resultSet ? (
                        <>
                          <p className="text-info mb-3">Apontar câmera</p>
                          <QRCode
                            value={selectedInstance.resultSet}
                            size={250}
                            level="M"
                            imageSettings={{
                              src: "https://foodsafetybrazil.org/wp-content/uploads/2021/01/icon.png",
                              excavate: true,
                              width: "50",
                              height: "50",
                            }}
                          />
                        </>
                      ) : (
                        <p className="text-danger">QR Code não disponível.</p>
                      )}
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={isOpenModalConfig} toggle={resetModalConfig} className="modal-md modal-dialog modal-dialog-centered">
                  <style>{`.modal-backdrop {background-color: rgba(0, 0, 0, 0.6)}`}</style>
                    <ModalHeader toggle={resetModalConfig}>Configurar envio de campanha</ModalHeader>
                    <ModalBody className="d-flex flex-column align-items-center justify-content-center mb-4">
                      <Card className="w-100 border-0">
                        <CardBody className="py-0 px-md-2 px-0">
                          <div className="mt-4 form-group w-100">
                            <strong>Intervalo de envio mensagens</strong>
                            <select
                              className="form-control"
                              name="minuteSendMessage"
                              value={instance.minuteSendMessage}
                              onChange={(ev) => {
                                const copyInstances = [...instances];
                                const currentIndex = copyInstances.findIndex(inst => inst.id === instance.id);
                                if (currentIndex !== -1) {
                                  copyInstances[currentIndex].minuteSendMessage = ev.target.value;
                                  setInstances(copyInstances);
                                }

                                setInterval(ev.target.value);
                              }}
                            >
                              <option value={1}>ENVIO A CADA 1 MINUTO</option>
                              <option value={2}>ENVIO A CADA 2 MINUTO(s)</option>
                              <option value={3}>ENVIO A CADA 3 MINUTO(s)</option>
                              <option value={4}>ENVIO A CADA 4 MINUTO(s)</option>
                              <option value={5}>ENVIO A CADA 5 MINUTO(s)</option>
                              <option value={10}>ENVIO A CADA 10 MINUTO(s)</option>
                              <option value={15}>ENVIO A CADA 15 MINUTO(s)</option>
                            </select>
                          </div>
                          <div className="mt-4 form-group w-100">
                            <strong>Horário execução rotina de envio</strong>
                            <FormGroup>
                              <InputGroup>
                                <Input
                                  type="time"
                                  name="minTimeExecute"
                                  value={instance.minTimeExecute}
                                  onChange={(ev) => {
                                    const copyInstance = instances;
                                    instances[key].minTimeExecute =
                                      ev.target.value;
                                    setInstances(copyInstance);
                                    setTimeSelect({
                                      ...timeSelect,
                                      minTimeExecute: ev.target.value,
                                    });
                                  }}
                                />
                                <Input
                                  type="time"
                                  name="maxTimeExecute"
                                  value={instance.maxTimeExecute}
                                  onChange={(ev) => {
                                    const copyInstance = instances;
                                    instances[key].maxTimeExecute =
                                      ev.target.value;
                                    setInstances(copyInstance);
                                    setTimeSelect({
                                      ...timeSelect,
                                      maxTimeExecute: ev.target.value,
                                    });
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </CardBody>
                      </Card>
                    </ModalBody>
                    <ModalFooter className="border-0 d-flex align-items-center justify-content-center pt-0">
                      <Button className="btn-success"
                        onClick={() => {
                          handleSaveSettings(instance.id);
                        }}
                      >
                        Salvar
                      </Button>
                      <Button className="btn-danger" onClick={resetModalConfig}>Sair</Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </>
            ))
          ) : (
            <div className="alert alert-info w-100">
              <h4 className="text-info m-0">
                Carregando... por favor aguarde!
              </h4>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Instance;