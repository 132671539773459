import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Tooltip,
} from "reactstrap";
import RegisterChatbotQuestionComponent from "./components/RegisterChatbotQuestionComponent";
import { listChatbotQuestionService, getChatbotById } from "../../services/Chatbot";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import "./Chatbot.module.css"

import TriggersRegisterModal from "./components/TriggersRegisterModal";

const ChatbotQuestionView = () => {
  const [list, setList] = useState([]);
  const { id: chatbot } = useParams();
  const [modalChatbotQuestion, setModalChatbotQuestion] = useState(false);
  const [chatbotQuestionId, setChatbotQuestionId] = useState(null);
  const [showModalTriggers, setShowModalTriggers] = useState(false);
  const [chatbotDetails, setChatbotDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [triggersModalDelayed, setTriggersModalDelayed] = useState(false);

  const tipoTraducao = {
    START: "INICIAR",
    CONTINUE: "EXECUTAR",
    FINISH: "FINALIZAR",
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });

  };

  const handleToggleModal = () => {
    setModalChatbotQuestion(!modalChatbotQuestion);
  };

  const openTriggersModal = (registerId) => {
    setChatbotQuestionId(registerId);
    setTriggersModalDelayed(true);
  };

  const handleListChatbotQuestion = (chatbotId) => {
    Swal.fire({
      title: "Carregando",
      html: "Por favor, aguarde ... ",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setLoading(true);
    listChatbotQuestionService(chatbotId)
      .then(({ data }) => {
        Swal.close();
        setList(data?.resultSet ?? []);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const toggleTriggersModal = () => {
    setShowModalTriggers(!showModalTriggers);
  };

  useEffect(() => {
    handleListChatbotQuestion(chatbot);
  }, []);

  useEffect(() => {
    getChatbotById(chatbot)
      .then((response) => {
        const { id, userId, instanceId, title, active, instanceName } = response.data.resultSet;
        setChatbotDetails({ id, userId, instanceId, title, active, instanceName });
      })
      .catch((error) => {
        console.error("Erro ao obter os detalhes do chatbot:", error);
      });

    handleListChatbotQuestion(chatbot);

    if (triggersModalDelayed) {
      setTimeout(() => {
        setShowModalTriggers(true);
        setTriggersModalDelayed(false);
      }, 3000);
    }
  }, [chatbot, triggersModalDelayed]);


  return (
    <>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Card className="border-0 shadow">
            <CardHeader className="bg-white border-0">
              <h3>Descrição</h3>
            </CardHeader>
            <CardBody>
              <Row >

                <Col className="pr-grid-col d-flex mb-2" xs={8} lg={12}>
                  <h5>
                    Título: {chatbotDetails.title}
                  </h5>
                </Col>
                <Col className="pr-grid-col d-flex" xs={8} lg={12}>
                  <h5>
                    Instância: <p className="badge badge-info">{chatbotDetails.instanceName}</p>
                  </h5>
                </Col>
              </Row>

            </CardBody>
          </Card>
          <Card className="border-0 shadow mt-4">
            <CardHeader className="bg-white border-0">
              <h3>Ações do chatbot</h3>
            </CardHeader>
            <CardFooter className="bg-white border-0">
              <Button
                color="success"
                onClick={() => {
                  setChatbotQuestionId(null);
                  handleToggleModal();
                }}
              >
                NOVO REGISTRO
              </Button>
            </CardFooter>
          </Card>
          {list.length > 0 && (
            <Card className="shadow border-0 mt-4">
              <CardHeader className="bg-white border-0">
                <h4>Registros</h4>
              </CardHeader>
              <Table>
                <thead>
                  <tr>
                    <th>DATA CADASTRO</th>
                    <th>TIPO</th>
                    <th>MENSAGEM</th>
                    <th>STATUS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((register, key) => (
                    <tr key={key}>
                      <td>{register.createdAt}</td>
                      <td>{tipoTraducao[register.type]}</td>
                      <td>
                        <Tooltip
                          className="align-self-start text-start w-auto"
                          placement="top"
                          isOpen={tooltipOpen[key]}
                          target={`tooltip-${key}`}
                          toggle={() => toggleTooltip(key)}
                          trigger="hover"
                          delay={{ show: 100, hide: 0 }}
                          style={{
                            backgroundColor: "#FFF",
                            border: "1px solid #C7D0D9",
                            color: "#000",
                            fontWeight: "500",
                            borderRadius: "5px",
                            width: "15rem",
                            padding: "10px",
                            whiteSpace: "pre-wrap",
                            textAlign: "start",
                            position: "absolute",
                            bottom: "10px"
                          }}
                        >
                          {register.question}
                        </Tooltip>
                        <div id={`tooltip-${key}`}>
                          {register.question.length > 20 ? `${register.question.slice(0, 20)}...` : register.question}
                        </div>
                      </td>
                      <td>
                        <Badge
                          color={
                            Number(register.active) === 1
                              ? "success"
                              : "warning"
                          }
                        >
                          {Number(register.active) === 1 ? "ATIVO" : "INATIVO"}
                        </Badge>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              style={{ color: "black" }}
                              onClick={() => {
                                setChatbotQuestionId(register.id);
                                handleToggleModal();
                              }}
                            >
                              Editar
                            </DropdownItem>
                            <DropdownItem
                              style={{ color: "black" }}
                              onClick={() => openTriggersModal(register.id)}
                            >
                              Gatilhos
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}
        </Col>
      </Row>
      <RegisterChatbotQuestionComponent
        id={chatbotQuestionId}
        chatbotId={chatbot}
        isOpen={modalChatbotQuestion}
        toggle={handleToggleModal}
        execute={() => {
          handleListChatbotQuestion(chatbot);
        }}
      />
      {showModalTriggers &&
        <TriggersRegisterModal
          toggleTriggersModal={() => setShowModalTriggers(false)}
          isOpen={showModalTriggers}
          chatbot={chatbot}
          chatbotQuestionId={chatbotQuestionId}
        />}
    </>

  );
};

export default ChatbotQuestionView;
